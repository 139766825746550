import * as React from "react";
import Layout from "../components/Layout/Layout";
import Button from "../components/Button/Button";
import shortCrookedLine from "../assets/img/short-crooked-line.svg";
import "../pages/error-page/error-page.scss";

const PlannedMaintenance = () => {
  return (
    <Layout>
      <div className="error-page-container">
        <h1 className="error-title">Job roles currently unavailable</h1>
        <img src={shortCrookedLine} alt="error-line" className="error-line" />
        <p className="error-message">
          There is planned maintenance taking place on our jobs board. We apologise for any trouble.
          We know it's not ideal but we'll be back in action later this morning.
        </p>
        <p className="error-message">
          All positions should be available. Whilst you wait, take a look at some useful pages on
          our careers website.
        </p>
        <div className="error-button">
          <Button dark route="/why-nandos" label="Why Nando's" />
          <Button dark route="/our-roles/early-careers" label="Early Careers" />
        </div>
      </div>
    </Layout>
  );
};

export default PlannedMaintenance;

export const Head = () => <meta name="robots" content="noindex" />;
